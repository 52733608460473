import { type FC, type PropsWithChildren, createContext, useState, useEffect } from 'react'
import { useAuth } from '../hooks/useAuth'

interface IDeviceContext {
  deviceCode: string
  setDeviceCode: (id: string) => void
  unathorized?: boolean
}

const DEVICE_CODE_KEY = 'crossfactory-device-code'

export const DeviceContext = createContext<IDeviceContext>({
  deviceCode: 'null',
  setDeviceCode: () => { },
  unathorized: false
})

const exceptionEmails = [
  'brumaycolchon@gmail.com'
]

export const DeviceProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth()

  const [deviceCode, setDeviceCode] = useState('')
  const [unathorized, setUnauthorized] = useState<boolean>()

  useEffect(() => {
    const id = localStorage.getItem(DEVICE_CODE_KEY)
    setDeviceCode(id!)
  }, [])

  useEffect(() => {
    if (user) {
      const deviceToBeSet = !user?.devices || user.devices.length === 0
      const tokenIsInDevices = user.devices?.includes(deviceCode)

      if (!tokenIsInDevices && !deviceToBeSet && !(exceptionEmails.includes(user.email))) {
        setUnauthorized(true)
      }
    }
  }, [deviceCode, user])

  return (
    <DeviceContext.Provider value={{ deviceCode, setDeviceCode, unathorized }}>
      {children}
    </DeviceContext.Provider>

  )
}
