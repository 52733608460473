import {
    IconBook,
    IconBookmarkQuestion,
    IconChevronRight,
    IconFileCertificate,
    IconFilePlus,
    IconHome,
    IconSettings,
    IconLogout2,
    IconUser,
    IconMenu2,
    IconX,
    IconMessage2,
    IconThumbDownOff,
    IconExchange,
    IconDeviceTv,
    IconAlertCircle
} from '@tabler/icons-react'

import './Sidebar.scss'
import { SidebarButton } from './components'
import { useCallback, useEffect, useState } from 'react'
import { SidebarUser } from './components/SidebarUser/SidebarUser'
import clsx from 'clsx'
import { PROTECTED_ROUTES } from '../../constants/routes'
import { useAuth } from '../../hooks/useAuth'
import { RoleEnum } from '../../types/role'

import Logo from '../../assets/cf-logo.png'
import { useBranch } from '../../hooks/useBranch'
import { Modal } from 'antd'

const Sidebar = () => {
    const { logout, user } = useAuth()
    const { removeBranch } = useBranch()

    const [alertModal, setAlertModal] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [navbarOpen, setNavbarOpen] = useState(false)

    const toggleSidebar = useCallback(() => {
        setIsOpen((prev) => !prev)
    }, [])

    const toggleNavbar = useCallback(() => {
        setNavbarOpen((prev) => !prev)
    }, [])

    useEffect(() => {
        const advised = localStorage.getItem('mallorcaoposita-advisor')

        if (!advised) {
            setAlertModal(true)
            localStorage.setItem('mallorcaoposita-advisor', 'true')
        }
    }, [])

    const isAdmin = (user?.role?.name === RoleEnum.superadmin || user?.role?.name === RoleEnum.admin)

    return (
        <div id="sidebar" className={clsx(isOpen ? 'sidebar-class open' : 'sidebar-class')}>
            <button className='toggler' onClick={toggleSidebar}>
                <IconChevronRight color='white' size="15px" className={clsx(isOpen ? 'arrow open' : 'arrow')} />
            </button>
            {navbarOpen
                ? (
                    <button className='navbar-toggler' onClick={toggleNavbar}>
                        <IconX color='white' size="15px" className={clsx(isOpen ? 'arrow open' : 'arrow')} />
                    </button>
                )
                : (
                    <button className='navbar-toggler' onClick={toggleNavbar}>
                        <IconMenu2 color='white' size="15px" className={clsx(isOpen ? 'arrow open' : 'arrow')} />
                    </button>
                )}

            <img src={Logo} className='sidebar-img' />
            <SidebarUser sidebarOpen={isOpen} />

            <div className={clsx('full-sections', navbarOpen && 'navbar-open')}>
                <div className="sections">
                    <SidebarButton label="Dashboard" sidebarOpen={isOpen} link={PROTECTED_ROUTES.DASHBOARD} onClick={toggleNavbar}>
                        <IconHome color="white" size={20} />
                    </SidebarButton>
                    {user?.testSection && (
                        <>
                            <SidebarButton label="Iniciar test" sidebarOpen={isOpen} link={PROTECTED_ROUTES.START_TEST} onClick={toggleNavbar}>
                                <IconFilePlus color="white" size={20} />
                            </SidebarButton>
                            <SidebarButton label="Mis resultados" sidebarOpen={isOpen} link={PROTECTED_ROUTES.RESULTS} onClick={toggleNavbar}>
                                <IconFileCertificate color="white" size={20} />
                            </SidebarButton>
                            <SidebarButton label="Preguntas destacadas" sidebarOpen={isOpen} link={PROTECTED_ROUTES.SAVED_QUESTIONS} onClick={toggleNavbar}>
                                <IconBookmarkQuestion color="white" size={20} />
                            </SidebarButton>
                        </>
                    )}
                    {user?.temarySection && (
                        <SidebarButton label="Temario" sidebarOpen={isOpen} link={PROTECTED_ROUTES.SUBJECTS} onClick={toggleNavbar}>
                            <IconBook color="white" size={20} />
                        </SidebarButton>
                    )}
                    <SidebarButton label="Clases online" sidebarOpen={isOpen} link={PROTECTED_ROUTES.ONLINE_SESSIONS} onClick={toggleNavbar}>
                        <IconDeviceTv color="white" size={20} />
                    </SidebarButton>
                    {isAdmin && (
                        <SidebarButton label="Preguntas Impugnadas" sidebarOpen={isOpen} link={PROTECTED_ROUTES.IMPUGNATED_QUESTIONS} onClick={toggleNavbar}>
                            <IconThumbDownOff color="white" size={20} />
                        </SidebarButton>
                    )}
                    <SidebarButton label="Foro" sidebarOpen={isOpen} link={PROTECTED_ROUTES.FORUM} onClick={toggleNavbar}>
                        <IconMessage2 color="white" size={20} />
                    </SidebarButton>
                </div>
                <div className="bottom-sections">
                    <SidebarButton label="Atención" sidebarOpen={isOpen} onClick={() => setAlertModal(true)}>
                        <IconAlertCircle color="white" size={20} />
                    </SidebarButton>
                    {isAdmin && (
                        <SidebarButton label="Administrar" sidebarOpen={isOpen} link={PROTECTED_ROUTES.ADMIN} onClick={toggleNavbar}>
                            <IconSettings color="white" size={20} />
                        </SidebarButton>
                    )}
                    <SidebarButton label="Perfil" sidebarOpen={isOpen} link={PROTECTED_ROUTES.PROFILE} onClick={toggleNavbar}>
                        <IconUser color="white" size={20} />
                    </SidebarButton>
                    <SidebarButton label="Cambiar de rama" sidebarOpen={isOpen} onClick={removeBranch}>
                        <IconExchange color="white" size={20} />
                    </SidebarButton>
                    <SidebarButton label="Cerrar sesión" sidebarOpen={isOpen} onClick={logout}>
                        <IconLogout2 color="white" size={20} />
                    </SidebarButton>
                </div>
            </div>
            <Modal
                open={alertModal}
                onCancel={() => setAlertModal(false)}
                onOk={() => setAlertModal(false)}
                okText="Aceptar"
                title="Atención"
            >
                {'Esta página web almacena información sobre tu dispositivo en la memoria de tu navegador. Esto significa que si vas a eliminar los datos de navegación y no excluyes este sitio de ese borrado, perderás esos datos y por tanto, el acceso a la plataforma y tendrás que contactar con el administrador para que habilite de nuevo tu acceso mediante este navegador. Por ello, asegurate de no borrar los datos de esta web.'}
            </Modal>
        </div >
    )
}

export { Sidebar }
